import {Component, EventEmitter, OnInit, Output} from '@angular/core';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {UntilDestroy, untilDestroyed} from '@ngneat/until-destroy';
import {INewPassForm} from './models/confirm-pass.types';
import {ConfirmPasswordValidator} from './services/confirm-pass.validators';


@UntilDestroy()
@Component({
	selector: 'app-confirm-pass',
	templateUrl: 'confirm-pass.component.html',
	styleUrls: ['confirm-pass.component.scss']
})
export class ConfirmPassComponent implements OnInit {
	newPassForm: FormGroup<INewPassForm>;
	outputPassControl: FormControl<string>;

	@Output() control = new EventEmitter<FormControl<string>>();

	constructor() {
	}

	ngOnInit(): void {
		this.initValues();
		this.initListeners();

		this.control.emit(this.outputPassControl);
	}

	initValues(): void {
		this.newPassForm = new FormGroup<INewPassForm>({
			newPassword: new FormControl<string>('', [Validators.required]),
			confirmNewPassword: new FormControl<string>(''),
		});

		this.newPassForm.get('confirmNewPassword')
			.addValidators(ConfirmPasswordValidator(this.newPassForm.get('newPassword')));

		this.outputPassControl = new FormControl<string>('');
		this.outputPassControl.setErrors({'error': true});
	}

	initListeners(): void {
		this.newPassForm.get('newPassword').valueChanges
			.pipe(
				untilDestroyed(this)
			).subscribe((res) => {
			const confirmPassControl = this.newPassForm.get('confirmNewPassword');

			this.outputPassControl.setValue(res);
			confirmPassControl.updateValueAndValidity();
			this.checkOutputControl();
		});

		this.newPassForm.get('confirmNewPassword').valueChanges
			.pipe(
				untilDestroyed(this)
			).subscribe((res) => {
			this.checkOutputControl();
		});
	}

	checkOutputControl(): void {
		if (this.newPassForm.get('confirmNewPassword').valid && this.newPassForm.get('newPassword').valid) {
			this.outputPassControl.updateValueAndValidity();
		} else {
			this.outputPassControl.setErrors({'error': true});
		}
	}

	checkConfirmedPass(event: Event): boolean {
		if (typeof event !== 'string') {
			return;
		}

		return this.newPassForm.get('newPassword').value === event;
	}

	changeVisiblePass(element: HTMLInputElement): void {
		element.type = element.type === 'password'
			? element.type = 'text' : element.type = 'password';
	}
}

import { Component, HostListener, OnInit } from '@angular/core';
import { AuthService } from '../../services/auth.service';
import { NotifierService } from 'angular-notifier';
import { Router } from '@angular/router';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { CookieService } from 'ngx-cookie-service';
import { ConfigService, IConfig } from '@shared/services/config.service';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { debounceTime, filter, first } from 'rxjs/operators';
import { F } from '@angular/cdk/keycodes';
import { SidebarService } from '../../../../layout/sidebar/services/sidebar.service';
import { getErrorMessage } from '@shared/utils';
import { URL_START_PAGE } from '../../../../app.component';
import { Subject } from 'rxjs';

@UntilDestroy()
@Component({
	selector: 'app-login',
	templateUrl: './login.component.html',
	styleUrls: ['./login.component.scss'],
})
export class LoginComponent implements OnInit {
	username: string;
	password: string;
	newPassword: string;

	loginForm: FormGroup;
	newPassControl: FormControl<string> = new FormControl<string>('');
	cardIdStr = '';
	resetPassUrl?: string;

	isNewPasswordVisible = false;

	isSubmitting$ = new Subject<void>();
	authBySmartCard$ = new Subject<string>();
	isSubmitting: boolean = false;

	constructor(
		private authService: AuthService,
		private notifierService: NotifierService,
		private router: Router,
		private cookieService: CookieService,
		private loaderService: NgxUiLoaderService,
		private configService: ConfigService,
		private sidebarService: SidebarService,
	) {
	}


	get Fusername() {
		return this.loginForm.get('Fusername');
	}

	get Fpassword() {
		return this.loginForm.get('Fpassword');
	}

	ngOnInit() {
		this.loginForm = new FormGroup({
			Fusername: new FormControl(this.username, [Validators.required]),
			Fpassword: new FormControl(this.password, [Validators.required]),
		});
		this.authService
			.getFrontDescription()
			.pipe(first())
			.subscribe(({ resetPassURL }: IConfig) => this.resetPassUrl = resetPassURL);

		this.isSubmitting$.pipe(
			untilDestroyed(this),
			debounceTime(250),
			filter(() => !this.isSubmitting),
		).subscribe(() => {
			if ((!this.isNewPasswordVisible && this.loginForm.valid)
				|| (this.loginForm.valid && this.newPassControl.valid)) {
				this.username = this.Fusername.value;
				this.password = this.Fpassword.value;
				this.newPassword = this.newPassControl.value;
				this.login();
			} else if (!this.loginForm.valid) {
				this.loginForm.reset();
			}
		});

		this.authBySmartCard$
			.pipe(
				untilDestroyed(this),
				debounceTime(250),
				filter(() => !this.isSubmitting),
			)
			.subscribe((pastedInput: string) => {
				this.smartCardEnter(pastedInput);
			});
	}

	onSubmit() {
		this.isSubmitting$.next();
	}

	private login() {
		this.isSubmitting = true;
		this.authService.clearData();
		const clientId = this.authService.getClientId();

		this.loaderService.start();

		this.authService.login(
			this.username.trim(),
			this.password.trim(),
			clientId,
			this.newPassword?.trim(),
		).pipe(first())
			.subscribe({
				next: (res) => {
					if (!res.isNew) {
						this.isNewPasswordVisible = false;
						localStorage.removeItem('smartCardLogin');
						this.loginInit(res);
					} else {
						this.isNewPasswordVisible = true;
						this.notifierService.notify('warning', 'Ваш старый пароль недействителен! Введите новый пароль!');
						this.loaderService.stop();
						this.isSubmitting = false;
					}
				},
				error: (error) => {
					if (error.status === 403) {
						this.notifierService.notify('error', getErrorMessage(error));
						this.isSubmitting = false;
					} else {
						this.notifierService.notify('error', getErrorMessage(error, 'Ошибка аутентификации'));
					}
					this.authService.isLoggedIn.next(false);
					this.loaderService.stop();
					this.isSubmitting = false;
				},
			});
	}

	private loginInit(res, bySmartCard?: boolean) {
		this.notifierService.notify('success', 'Успешно авторизованы');

		localStorage.setItem('currentUser', JSON.stringify({
			fullName: res['displayName'],
			email: res['email'],
			role: res['role'],
			tabNumber: res['tabNumber'],
			userId: res['userId'],
			mesRole: res['mesRole'],
			userName: res['userName'],
			image: res['image'],
			restTimePassword: res['restTimePassword'],
		}));

		this.cookieService.set('accessToken', res['accessToken'], null, '/');
		this.cookieService.set('refreshToken', res['refreshToken'], null, '/');
		this.cookieService.set('login', res['userName'], null, '/');

		this.authService.isLoggedIn.next(true);
		this.loaderService.stop();

		let route = localStorage.getItem('storedUrl') || URL_START_PAGE;

		if (bySmartCard) {
			route = '/service-desk/service';
			this.sidebarService.typeSidebar.next('terminal');
			this.authService.setIsTimeSession();
			this.authService.startLogoutTimer();
		} else {
			this.sidebarService.typeSidebar.next('default');
		}

		this.router.navigateByUrl(route);
	}

	remoteSmartCardEnter() {
		const elem = document.getElementById('username');

		this.cardIdStr = elem['value'];
		this.authBySmartCard$.next(this.cardIdStr);
	}

	smartCardEnter(pastedInput: string) {
		this.isSubmitting = true;
		const clientId = this.authService.getClientId();

		this.loaderService.start();

		this.authService.loginBySmartCard(pastedInput, clientId)
			.pipe(untilDestroyed(this))
			.subscribe({
				next: res => {
					localStorage.setItem('smartCardLogin', 'loginBySmartCard');
					this.loginInit(res, true);
				},
				error: error => {
					this.notifierService.notify('error', getErrorMessage(error));
					this.authService.isLoggedIn.next(false);
					this.loaderService.stop();
					this.isSubmitting = false;
				},
			});
	}

	setConfirmPassControl(control: FormControl): void {
		this.newPassControl = control;
	}

	@HostListener('document:paste', ['$event'])
	onPaste(event: ClipboardEvent) {
		const pastedInput: string = event.clipboardData.getData('text/plain');
		// get a digit-only string
		// this.notifier.notify('warning', 'Paste:' + pastedInput + ';');

		this.cardIdStr = '';
		this.cardIdStr = pastedInput;
		// this.smartCardEnter(pastedInput);
	}

	@HostListener('document:keydown', ['$event'])
	onkeydown(event: KeyboardEvent) {
		let key: string = event.code;

		if (event.key.length === 1) {
			key = key.slice(event.code.length - 1, event.code.length);
		}

		if ((!this.Fusername.value || this.Fusername.value.length === 0) &&
			(!this.Fpassword.value || this.Fpassword.value.length === 0)) {
			if (key.length === 1) {
				this.cardIdStr += key;
				// tslint:disable-next-line:triple-equals
			} else if (key.includes('Enter') && this.cardIdStr != '') {
				this.authBySmartCard$.next(this.cardIdStr.toUpperCase());
			}
		} else {
			this.cardIdStr = '';
		}
	}

	changeVisiblePass(element: HTMLInputElement): void {
		element.type = element.type === 'password'
			? element.type = 'text' : element.type = 'password';
	}

	protected readonly F = F;
}

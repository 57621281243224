import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';

import {LoginComponent} from './components/login/login.component';

const routes: Routes = [
	{
		path: 'login',
		component: LoginComponent,
		data: {title: 'Auth', breadcrumb: 'Аутентификация'}
	}
];

@NgModule({
	imports: [RouterModule.forChild(routes)]
})
export class AuthRouting {
}

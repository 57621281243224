<form [formGroup]="newPassForm"
>
	<div class="form-group">
		<div class="input-group">
			<input
				#newPass
				type="password"
				class="form-control"
				formControlName="newPassword"
				placeholder="Новый пароль"
				id="newPassword"
				[ngClass]="{
					'is-invalid':  newPassForm.get('newPassword').invalid && newPassForm.get('newPassword').dirty,
					'is-valid': newPassForm.get('newPassword').valid && newPassForm.get('newPassword').dirty
				}"/>
			<div class="input-group-text" (click)="changeVisiblePass(newPass)">
				<i
					*ngIf="newPass.type === 'password'"
					class="bi bi-eye-slash"
				></i>
				<i
					*ngIf="newPass.type === 'text'"
					class="bi bi-eye"
				></i>
			</div>
		</div>

		<span
			*ngIf="newPassForm.get('newPassword').invalid && newPassForm.get('newPassword').dirty"
			class="text-danger"
		>Введите новый пароль</span>
	</div>

	<div class="form-group">
		<div class="input-group">
			<input
				#confirmNewPass
				type="password"
				class="form-control"
				formControlName="confirmNewPassword"
				placeholder="Подтверждение нового пароля"
				(change)="checkConfirmedPass($event)"
				[ngClass]="{
					'is-invalid':  newPassForm.get('confirmNewPassword').invalid && newPassForm.get('confirmNewPassword').dirty,
					'is-valid': newPassForm.get('confirmNewPassword').valid && newPassForm.get('confirmNewPassword').dirty
				}"/>
			<div class="input-group-text" (click)="changeVisiblePass(confirmNewPass)">
				<i
					*ngIf="confirmNewPass.type === 'password'"
					class="bi bi-eye-slash"
				></i>
				<i
					*ngIf="confirmNewPass.type === 'text'"
					class="bi bi-eye"
				></i>
			</div>
		</div>

		<span
			*ngIf="newPassForm.get('confirmNewPassword').invalid && newPassForm.get('confirmNewPassword').touched"
			class="text-danger"
		>Подтвердите новый пароль</span>
	</div>
</form>

import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';

import {AuthService} from './services/auth.service';
import {AuthRouting} from './auth.routing';

@NgModule({
	declarations: [],
	imports: [
		CommonModule,
		FormsModule,
		AuthRouting,
		ReactiveFormsModule
	],
	providers: [AuthService]
})
export class AuthModule {
}

<form
	class="login"
	[formGroup]="loginForm"
	(ngSubmit)="onSubmit()"
>
	<div class="logo" style="text-align: center">
		<!--			<img src="assets/img/owl-logo-transparent.png" alt="Owl SCADA">-->
	</div>
	<div class="form-group">
		<input
			type="text"
			class="form-control"
			placeholder="Имя пользователя"
			formControlName="Fusername"
			required
			[ngClass]="{
				'is-invalid': (Fusername.dirty || Fusername.touched) && Fusername.errors,
				'is-valid': (Fusername.dirty || Fusername.touched) && !Fusername.errors
			}" />
		<span
			*ngIf="(Fusername.dirty || Fusername.touched) && Fusername.hasError('required')"
			class="error-message"
		>Введите логин
		</span>
		@if ((Fusername.dirty || Fusername.touched) && Fusername.hasError('required')) {
			<app-icon class="icon red" name="important" />
		} @else if ((Fusername.dirty || Fusername.touched) && !Fusername.errors) {
			<app-icon class="icon green" name="done" />
		}
	</div>
	<div class="form-group">
		<div class="input-group">
			<input
				#password
				type="password"
				class="form-control"
				formControlName="Fpassword"
				placeholder="Пароль"
				required
				id="password"
				[ngClass]="{
					'is-invalid': (Fpassword.dirty || Fpassword.touched) && Fpassword.errors,
					'is-valid': (Fpassword.dirty || Fpassword.touched) && !Fpassword.errors
				}" />
			@if ((Fpassword.dirty || Fpassword.touched) && Fpassword.hasError('required')) {
				<app-icon class="icon red" style="right: 50px" name="important" />
			} @else if ((Fpassword.dirty || Fpassword.touched) && !Fpassword.errors) {
				<app-icon class="icon green" style="right: 50px" name="done" />
			}
			<div class="input-group-text" (click)="changeVisiblePass(password)">
				@if (password.type === 'password') {
					<mat-icon style="margin-left: 4px;margin-top: 4px;" svgIcon="eye" />
				} @else {
					<mat-icon style="margin-left: 4px;margin-top: 4px;" svgIcon="eye-slash" />
				}
			</div>
		</div>
	</div>
	<br>
	<app-confirm-pass *ngIf="isNewPasswordVisible"
					  (control)="setConfirmPassControl($event)"
	></app-confirm-pass>

	<app-button
		class="g-button-primary-square"
		style="float: right"
		type="submit"
		[disabled]="!Fusername.dirty || !Fpassword.dirty || loginForm.invalid || newPassControl.invalid || isSubmitting"
	>
		Войти
	</app-button>
	<div style="display:flex;">
		<mat-icon svgIcon="id-card" style="width: 20px; height: 16px"></mat-icon>
		<span>Для входа по пропуску, приложите пропуск к считывающему устройству</span>
	</div>
	<span>(поля ввода логина и пароля при этом должны быть пустыми)</span>
	<br>
	<br>
	<a
		*ngIf="resetPassUrl"
		class="link-password"
		target="_blank"
		[href]="resetPassUrl"
	>Сменить пароль</a>
</form>


<input
	hidden
	id="username"
	class="form-control"
	[(ngModel)]="cardIdStr"
	(change)="remoteSmartCardEnter()"
>
